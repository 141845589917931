
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import SidebarCaption from '@/components/commonComponents/SidebarCaption.vue';
import SidebarMenuItem from '@/components/commonComponents/SidebarMenuItem.vue';
import SidebarMenuMapper from '@/helpers/mappers/sidebar-menu-mapper';
import Message from '@/components/mixins/Message.vue';
import useTenantSettingsStore from '@/stores/tenantSettings';
import { mapStores } from 'pinia';
import { FetchPolicy } from 'apollo-client';
import { DocumentNode } from 'graphql';
import EventBus from '@/event-bus';

@Component({
  name: 'Sidebar',
  components: {
    SidebarCaption,
    SidebarMenuItem,
  },
  computed: {
    ...mapStores(useTenantSettingsStore),
  },
})
export default class Sidebar extends Vue {
  private SidebarMenuMapper = new SidebarMenuMapper();

  private slug = this.$route.params.navigationSlug;

  private context = this.$route.params.dossierTypeSlug ?? null;

  private loading = false;

  mounted(): void {
    const updateSidebar = () => this.fetchData('network-only');

    EventBus.$on('forceRerender', updateSidebar);

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('sidebar:update', updateSidebar);
      EventBus.$off('forceRerender', updateSidebar);
    });

    this.fetchData();
  }

  private getParamName(): string {
    switch (this.$route.name) {
      case 'dossier-type-list':
      case 'dossier-detail':
        return 'dossiers';
      case 'action-list':
        return 'actions';
      case 'report-list':
      case 'report-detail':
        return 'reports';
      default:
        throw new Error(`Could not set required paramName in Sidebar.vue because there is no matched route name. Current route: ${this.$route}`);
    }
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first') {
    this.loading = true;

    import('@/graphql/queries/menu')
      .then(({ MENU: query }) => {
        if (this.SidebarMenuMapper.items.length === 0) {
          this.getMenu(query, fetchPolicy);
        }
        return query;
      }).then((query) => this.getMenu(query, 'no-cache', true))
      .finally(() => {
        this.loading = false;
      });
  }

  private async getMenu(
    query: DocumentNode,
    fetchPolicy: FetchPolicy = 'cache-first',
    addCounters = false,
  ): Promise<void> {
    const variables = {
      name: this.getParamName(),
      slug: this.slug,
      context: this.context,
      addCounters,
    };

    return this.$apollo.query({
      query,
      variables,
      fetchPolicy,
    })
      .then((response) => {
        this.SidebarMenuMapper.input = response.data.menu.items;
        this.SidebarMenuMapper.map();
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      });
  }
}
